<script lang="ts" setup>
import type { CategoryRaw } from "~/types";

const props = defineProps<{
  id: string;
  processId: number | null;
  internal: boolean | null;
  status: string;
  synopsis: string;
  createdAt: string;
  reduced?: boolean;
  createdByUser?: {
    id: string;
    username: string;
  } | null;
  lastUpdate?: {
    date: string;
    user: {
      id: string;
      username: string;
    };
  }[];
  claimCategories: CategoryRaw[];
  userClaimStatuses: {
    hasMarked: boolean;
    hasRead: boolean;
  }[];
}>();
const route = useRoute();
const active = computed(() => route.path.includes(props.id));
</script>

<template>
  <BasicCard
    :to="`/claims/${route.params.filter}/${id}${route.query.search ? `?search=${route.query.search}` : ''}`"
    :class="{ 'border-neutral-8': active }"
  >
    <div class="flex gap-1 items-start">
      <div class="flex gap-2 items-center">
        <UnreadIcon :has-read="userClaimStatuses[0]?.hasRead" />
        <h3
          :class="
            cn('h2 py-0.25 px-1.5 text-neutral-6 rounded-md border border-neutral-3', {
              'border-neutral-8 text-neutral-8': active
            })
          "
        >
          #{{ processId }}
        </h3>
      </div>
      <CategoriesCard :claim-categories />
      <div class="ml-auto flex gap-1">
        <ClaimInternalButton :claim-id="id" :internal size="xs" />
        <ClaimUserStatusButtons
          v-if="id"
          :claim-id="id"
          :user-status="userClaimStatuses[0]"
          size="xs"
          show-has-read
        />
      </div>
    </div>
    <p class="base font-serif grow overflow-hidden">{{ synopsis }}</p>
    <CreatedUpdated
      v-if="!props.reduced"
      :created-at="createdAt"
      :created-by-user="createdByUser"
      :last-update="lastUpdate"
      minimized
    />
  </BasicCard>
</template>
